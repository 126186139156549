<template>
  <vx-card title="Approval Generate Invoice">
    <vs-tabs>
      <vs-tab label="Open"><open /></vs-tab>
      <vs-tab label="Generated"><generated /></vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import open from "./open/index.vue";
import generated from "./generated/index.vue";
export default {
  components: {
    open,
    generated,
  },
};
</script>
