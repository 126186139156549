<template>
  <div class="flex flex-col gap-4">
    <vs-input
      v-model="podData.shipment_number"
      label="Shipment Number"
      class="w-full"
      readonly
    />
    <vs-input v-model="podDate" label="POD Date" class="w-full" readonly />
    <vs-input v-model="podStatus" label="Status" class="w-full" readonly />
    <vs-input
      v-model="podData.customer_code"
      label="Customer Code"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.customer_name"
      label="Customer Name"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.customer_address"
      label="Customer Address"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.do_code"
      label="Delivery Order Code"
      class="w-full"
      readonly
    />
    <vs-input
      v-model="podData.so_code"
      label="Sales Order Code"
      class="w-full"
      readonly
    />

    <h3 class="mt-4">Detail</h3>
    <vs-table :data="skuData">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Price</vs-th>
        <vs-th>Qty</vs-th>
        <vs-th>Unit</vs-th>
        <vs-th>Subtotal</vs-th>
        <vs-th>Tax</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Total</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.ItemName">
            ({{ tr.SkuCode }}) {{ tr.ItemName }}
          </vs-td>

          <vs-td :data="tr.Price" style="text-align: right;">
            {{ priceFormatNoDecimal(tr.Price) }}
          </vs-td>

          <vs-td :data="tr.Quantity">
            {{ tr.Quantity }}
          </vs-td>

          <vs-td :data="tr.UnitName">
            {{ tr.UnitName }}
          </vs-td>

          <vs-td style="text-align: right;">
            {{ priceFormatNoDecimal(tr.Price*tr.Quantity) }}
          </vs-td>

          <vs-td :data="tr.TaxAmount" style="text-align: right;">
            {{ priceFormatNoDecimal(tr.TaxAmount) }}
          </vs-td>

          <vs-td :data="tr.Discount" style="text-align: right;">
            {{ priceFormatNoDecimal(tr.Discount) }}
          </vs-td>

          <vs-td :data="tr.Total" style="text-align: right;">
            {{ priceFormatNoDecimal(tr.Total) }}
          </vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <b></b>
          </vs-td>

          <vs-td>
            <b>TOTAL</b>
          </vs-td>

          <vs-td>
            <b></b>
          </vs-td>

          <vs-td>
            <b></b>
          </vs-td>

          <vs-td style="text-align: right;">
            <b>{{ priceFormatNoDecimal(totalSubtotal) }}</b>
          </vs-td>

          <vs-td style="text-align: right;">
            <b>{{ priceFormatNoDecimal(totalTax) }}</b>
          </vs-td>

          <vs-td style="text-align: right;">
            <b>{{ priceFormatNoDecimal(totalDiscount) }}</b>
          </vs-td>

          <vs-td style="text-align: right;">
            <b>{{ priceFormatNoDecimal(grandTotal) }}</b>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    podData: Object,
  },
  data() {
    return {
      podStatus: "",
      podDate: "",
      detailData: [],
      skuData: [],
      totalPrice: 0,
      totalSubtotal: 0,
      totalTax: 0,
      totalDiscount: 0,
      grandTotal: 0,
      //   itemList
    };
  },
  mounted() {
    console.log("podData", this.podData);
    this.podStatus = this.generatePODStatus(this.podData.status_delivery);
    this.podDate = this.podData.date
      ? moment(this.podData.date).format("DD-MM-YYYY")
      : "";

    this.getDetail(this.podData.do_id, this.podData.do_code);
    if (this.podData.transaction != null) {
      this.skuData = [...this.podData.transaction.line_convertion]
    }
    this.totalPrice = this.skuData.map(item => item.Price).reduce((prev, curr) => prev + curr, 0)
    this.totalSubtotal = this.skuData.map(item => (item.Price*item.Quantity)).reduce((prev, curr) => prev + curr, 0)
    this.totalTax = this.skuData.map(item => item.TaxAmount).reduce((prev, curr) => prev + curr, 0)
    this.totalDiscount = this.skuData.map(item => item.Discount).reduce((prev, curr) => prev + curr, 0)
    this.grandTotal = this.skuData.map(item => item.Total).reduce((prev, curr) => prev + curr, 0)
  },
  methods: {
    generatePODStatus(statusNumber) {
      switch (statusNumber) {
        case 0:
          return "Open";
        case 1:
          return "Complete";
        case 2:
          return "Partial Complete";
        case 3:
          return "Partial Redeliver";
        case 4:
          return "Failed Complete";
        case 5:
          return "Failed Redeliver";
      }
    },
    getDetail(delivery_order_id, delivery_order_code) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/proof-of-delivery/detail", {
          params: {
            delivery_order_id,
            delivery_order_code,
          },
        })
        .then((r) => {
          console.log(r);
          if (r.code == 500) {
            this.$vs.loading.close();
          } else if (r.code == 200) {
            this.$vs.loading.close();
            // this.data = r.data.payment;
            this.detailData = r.data;
            console.log("r.data", r.data);
            // this.responseData.length = r.data.payment.length;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    podData() {
      console.log("watching data: "+ this.podDate);
      this.podStatus = this.generatePODStatus(this.podData.status_delivery);
      this.podDate = this.podData.date
        ? moment(this.podData.date).format("DD-MM-YYYY")
        : "";

      this.getDetail(this.podData.do_id, this.podData.do_code);
    }
  }
};
</script>
