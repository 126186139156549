<template>
  <div class="flex flex-col gap-3 w-full">
    <div class="flex gap-3 w-full">
      <vs-button @click="handleSubmit">Submit</vs-button>
    </div>
    <div class="vx-row mb-12 mt-2">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <vs-th style="width: 13%">
              <vs-checkbox v-model="approveAll"> Approve All </vs-checkbox>
            </vs-th>
          </template>

          <template slot="thead">
            <vs-th style="width: 13%">
              <vs-checkbox v-model="rejectAll"> Reject All </vs-checkbox>
            </vs-th>
          </template>

          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
              <vs-td
                ><vs-checkbox
                  @click="addCheck(tr.id, 'approve')"
                  :checked="isChecked(tr.id, 'approve')"
              /></vs-td>
              <vs-td
                ><vs-checkbox
                  @click="addCheck(tr.id, 'reject')"
                  :checked="isChecked(tr.id, 'reject')"
              /></vs-td>
              <vs-td>
                <vx-tooltip text="Details" class="mr-4">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-on:click.stop="handleShow(tr)"
                  /> </vx-tooltip
              ></vs-td>
              <vs-td> {{ tr.shipment_number }}</vs-td>
              <vs-td>
                Code : {{ tr.customer_code }}
                <br />
                Name : {{ tr.customer_name }}
                <br />
                Address : {{ tr.customer_address }}
                <br />
              </vs-td>
              <vs-td>
                Code : {{ tr.so_code }}
                <br />
                Posting Date :
                {{ tr.so_posting_date ? tr.so_posting_date.split("T")[0] : "" }}
                <br />
                Invoice Code : {{ tr.so_invoice_code }}
              </vs-td>
              <vs-td>
                Code : {{ tr.do_code }}
                <br />
                Posting Date :
                {{ tr.do_posting_date ? tr.do_posting_date.split("T")[0] : "" }}
              </vs-td>
              <vs-td>
                {{ capitalize(tr.notes) }}
              </vs-td>
              <vs-td>
                {{ generatePODStatus(tr.status_delivery) }}
              </vs-td>
              <vs-td>
                <template v-if="tr.transaction != null">
                  Subtotal :
                  {{ priceFormatNoDecimal(tr.transaction.header.TotalAmount) }}
                  <br />
                  Tax :
                  {{
                    priceFormatNoDecimal(tr.transaction.header.TotalTaxAmount)
                  }}
                  <br />
                  Discount :
                  {{
                    priceFormatNoDecimal(tr.transaction.header.TotalDiscount)
                  }}
                  <br />
                  Total :
                  {{
                    priceFormatNoDecimal(
                      tr.transaction.header.TotalAmountAfterTaxAndDiscount
                    )
                  }}
                  <br />
                </template>
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div class="flex flex-col">
            <div
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </div>
            <!-- <form-apply :payment="selectedData" @closeDetail="closeDetail" /> -->
            <pod_detail :podData="selectedData" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import pod_detail from "./detail.vue";
export default {
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "shipment_number",
      },
      header: [
        {
          text: "Action",
        },
        {
          text: "Shipment Doc",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Customer Data",
          value: "reference_code",
        },
        {
          text: "SO Data",
          sortable: false,
        },
        {
          text: "DO/DN Data",
          value: "created_at",
        },
        {
          text: "Source",
          value: "created_at",
        },
        {
          text: "POD Status",
          value: "created_at",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      approveAll: false,
      rejectAll: false,
      aprrovedCheckedID: [],
      rejectedCheckedID: [],
    };
  },
  components: {
    pod_detail,
  },
  watch: {
    approveAll() {
      if (this.approveAll) {
        this.aprrovedCheckedID = this.responseData.payment.map(
          (item) => item.ID
        );

        this.rejectAll = false;
        this.rejectedCheckedID = [];
      } else {
        this.aprrovedCheckedID = [];
      }
    },
    rejectAll() {
      if (this.rejectAll) {
        this.rejectedCheckedID = this.responseData.payment.map(
          (item) => item.ID
        );

        this.approveAll = false;
        this.aprrovedCheckedID = [];
      } else {
        this.rejectedCheckedID = [];
      }
    },
  },
  methods: {
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    closeDetail(params = false) {
      // window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    applyPayment(tr) {
      this.detail = true;
      this.selectedData = tr;
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    datatable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/proof-of-delivery", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              type: 1,
              shipment_number: null,
              warehouse_id: 0,
              start_delivery_date: null,
              end_delivery_date: null,
              territory_ids: params.territory_ids,
              customer_codes: params.customer_codes.includes("All")
                ? undefined
                : params.customer_codes,
              is_ar_invoice: true,
              status: params.status ? params.status : null,
              // type: params.type ? params.type : 0,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      // console.log("territory ids", territory_ids);
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.params = {
        ...params,
        territory_ids,
        customer_codes,
        status: 7,
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.datatable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          // this.data = r.data.payment;
          this.responseData = r.data;
          console.log("r.data", r.data);
          // this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },

    generatePODStatus(statusNumber) {
      switch (statusNumber) {
        case 0:
          return "Open";
        case 1:
          return "Complete";
        case 2:
          return "Partial Complete";
        case 3:
          return "Partial Redeliver";
        case 4:
          return "Failed Complete";
        case 5:
          return "Failed Redeliver";
      }
    },
    capitalize(str) {
      if (str.includes("ePod") || str.includes("Pod")) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    addCheck(id, type = "approve") {
      switch (type) {
        case "approve":
          if (this.aprrovedCheckedID.includes(id)) {
            const index = this.aprrovedCheckedID.indexOf(id);
            this.aprrovedCheckedID.splice(index, 1);
          } else {
            this.aprrovedCheckedID.push(id);
            if (this.rejectedCheckedID.includes(id)) {
              const index = this.rejectedCheckedID.indexOf(id);
              this.rejectedCheckedID.splice(index, 1);
            }
          }

          if (this.rejectAll) {
            this.rejectAll = false;
          }
          break;
        case "reject":
          if (this.rejectedCheckedID.includes(id)) {
            const index = this.rejectedCheckedID.indexOf(id);
            this.rejectedCheckedID.splice(index, 1);
          } else {
            this.rejectedCheckedID.push(id);
            if (this.aprrovedCheckedID.includes(id)) {
              const index = this.aprrovedCheckedID.indexOf(id);
              this.aprrovedCheckedID.splice(index, 1);
            }
          }
          if (this.approveAll) {
            this.approveAll = false;
          }
          break;
      }
    },
    isChecked(id, type = "approve") {
      if (type == "approve") {
        return this.aprrovedCheckedID.includes(id);
      } else {
        return this.rejectedCheckedID.includes(id);
      }
    },
    validateSelectedPOD() {
      if (
        this.aprrovedCheckedID.length == 0 &&
        this.rejectedCheckedID.length == 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one POD",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (!this.validateSelectedPOD()) {
        return;
      }

      this.$vs.loading();
      this.$http
        .patch("/api/v1/invoice/pod/status", {
          approved_ids: this.aprrovedCheckedID,
          rejected_ids: this.rejectedCheckedID,
        })
        .then((r) => {
          console.log(r);
          if (r.code == 500) {
            this.$vs.loading.close();
          } else if (r.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "POD has been processed",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.error(e);
        });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 46%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
